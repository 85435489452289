import { Col, Card } from "react-bootstrap"
import {NavLink} from "react-router-dom"
import noImage from "../../../assets/images/no-image.png";

const ProductCard = ({product_detail}) => {
    const showErrImage = (e) => {
        e.target.src = noImage
    }
    return (<>
    <Col sm={6} md={3} className="mb-3" >
        <Card>
            <NavLink to={`/product/${product_detail.slug}`}>
                <Card.Img variant="top" src={process.env.REACT_APP_IMAGE_URL+product_detail.images[0]} onError={showErrImage} />
            </NavLink>
            <Card.Body>
                <Card.Title>
                    <NavLink to={`/product/${product_detail.slug}`} style={{textDecoration: "none", color: "black"}}>
                        <h5>{product_detail.name}</h5>
                    </NavLink>
                </Card.Title>
                <Card.Text as="div"> 
                    <p>
                        <NavLink to={`/category/${product_detail.category_id.slug}`} style={{textDecoration: "none", color: "grey"}}>
                            <em>{product_detail.category_id.name}</em>
                        </NavLink>
                    </p>
                    <p>
                        <span className="pe-3">Npr. {product_detail.actual_price}</span>
                        {
                            (product_detail.discount && product_detail.discount > 0) ?
                            <>
                                <small className="text-danger"><del className="text-danger">Npr. {product_detail.price} </del>({product_detail.discount}% off)</small>
                            </>
                            : <></> 
                        }
                    </p>
                </Card.Text>
            </Card.Body>
        </Card>
    </Col>
    </>)
}
export default ProductCard