import "./title.component.css";

const AuthTitle = (props) => {
    // const AuthTitle = ({title, subtitle})=>{
    return (

        <div className="row">
            <div className="col-12 text-center">
                <h1>{props.title}</h1>
                <h4>{props.subtitle}</h4>
            </div>
        </div>
    );
}
export default AuthTitle;