import {BrowserRouter, Routes, Route, Outlet} from "react-router-dom"
import HomePage from "./home/home.page";
import RegisterPage from "./auth/register.page"
import ErrorPage from "./error/error.page";
import AdminPrivateLayout from "./private/adminPrivateLayout.page";

import { ToastContainer  } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import HomeLayout from "./layouts/home.layout";
import Admin from "./admin";
import {Provider} from "react-redux";
import store from "../store";
import CategoryDetailList from "./home/category-detail-list.page";
import ProductDetailPage from "./home/product-detail.page";
import CartPage from "./home/cart.page";
import Checkout,{ LoginLayout } from "./home/checkout.page";


const AdminComponent = (props) => {
    return (<>{props.title} <Outlet /></>)
}

const App =() => {
    return (
        <Provider store={store}>
            <BrowserRouter>
                <ToastContainer 
                    theme="colored"
                />
                <Routes>
                    <Route path="/" element={<HomeLayout />}>
                        <Route index element={<HomePage />}/>
                        <Route path="register" element={<RegisterPage />} />
                        <Route path="category/:slug" element={<CategoryDetailList />}></Route>
                        <Route path="login" element={<RegisterPage />} />
                        <Route path="product/:slug" element={<ProductDetailPage />} />
                        <Route path="cart" element={<CartPage />} />

                        <Route path="checkout" element={<LoginLayout Compontent={<Checkout />} />} />
                    </Route>
                    
                    <Route path="/admin" element={<AdminPrivateLayout Compontent={<Admin.AdminLayout />} />}>
                        <Route index element={<Admin.AdminDashboard/>}></Route>
                        <Route path="profile" element={<AdminComponent title="Profile"/>}></Route>

                        <Route path="brand" element={<Admin.BrandListPage />}></Route>
                        <Route path="brand/create" element={<Admin.BrandCreatePage />}></Route>
                        <Route path="brand/:brand_id/edit" element={<Admin.BrandEditPage />}></Route>

                        <Route path="banner" element={<Admin.BannerListPage />}></Route>
                        <Route path="banner/create" element={<Admin.BannerCreatePage />}></Route>
                        <Route path="banner/:banner_id/edit" element={<Admin.BannerEditPage />}></Route>

                        <Route path="category" element={<Admin.CategoryListPage />}></Route>
                        <Route path="category/create" element={<Admin.CategoryCreatePage />}></Route>
                        <Route path="category/:category_id/edit" element={<Admin.CategoryEditPage />}></Route>

                        <Route path="product" element={<Admin.ProductListPage />}></Route>
                        <Route path="product/create" element={<Admin.ProductCreatePage />}></Route>
                        <Route path="product/:product_id/edit" element={<Admin.ProductEditPage />}></Route>

                        <Route path="user" element={<Admin.UserListPage />}></Route>
                        <Route path="user/create" element={<Admin.UserCreatePage />}></Route>
                        <Route path="user/:user_id/edit" element={<Admin.UserEditPage />}></Route>
                        
                    </Route>
                    
                    <Route path="*" element={<ErrorPage />}></Route>
                </Routes>
            </BrowserRouter>    
        </Provider>
    )
}

export default App;