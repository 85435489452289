import { createSlice } from "@reduxjs/toolkit";

const CartSlice = createSlice({
    name: "cart",
    initialState: {
        cartDetail: []
    },
    reducers: {
        setItemtoCart: (state, action) => {
            // localstorage cart 
            let current_cart = state.cartDetail;
            if(current_cart && current_cart.length >0) {
                // cart exists
                let index = null;
                current_cart.forEach((item, ind) => {
                    if(item.product_id === action.payload.product_id) {
                        //
                        index = ind;
                    }
                } )
                if(index === null) {
                    current_cart.push(action.payload)
                } else {
                    if(action.payload.qty <= 0){
                        current_cart.splice(index, 1);
                    } else {
                        current_cart[index]['qty'] = action.payload.qty;
                    }
                    
                }
            } else {
                // cart is empty 
                current_cart.push(action.payload)
            }

            localStorage.setItem('cart_detail', JSON.stringify(current_cart));
        },
        setCartDetailFromLocalStorage: (state, action) => {
            state.cartDetail = action.payload
        }
    }
})

export const {setItemtoCart, setCartDetailFromLocalStorage} = CartSlice.actions;
export default CartSlice.reducer;