import AdminBreadCrumb from "../../../components/admin/admin-bredcrumb.component"
import BrandForm from "./brand-form.component"
import BrandService from "../../../services/brand.service"
import { toast } from "react-toastify"
import { useNavigate, useParams } from "react-router-dom"
import { useCallback, useEffect, useState } from "react"

const BrandEditPage = () => {
    let [brand, setbrand] = useState();
    let params = useParams();
    let navigate = useNavigate();
    let brand_svc = new BrandService()

    const getBrandDetail = useCallback(async () => {
        try {

            let brand_data = await brand_svc.getBrandById(params.brand_id);
            if(brand_data){
                setbrand(brand_data.result)
            } else {
                toast.info("Brand does not exists");
                navigate("/admin/brand")
            }
        } catch(except) {
            console.log(except);
        }
    }, [])

    useEffect(() => {
        getBrandDetail()
    }, [getBrandDetail]);

    const submitForm = async (data) => {
        try{
            let updatedata = {
                title: data.title, 
                status: data.status, 
                image: data.image
            }

            let response = await brand_svc.update(updatedata,params.brand_id);
            toast.success(response.msg)
            navigate("/admin/brand")
        } catch(err){
            toast.error(err);
        }
    }
    return (<>
        <div className="container-fluid px-4">
            <AdminBreadCrumb title={"Brand Edit"} lable="Brand" showList={true}/>
            <div className="card mb-4">
                <div className="card-body">
                    <BrandForm data={brand} submitForm={submitForm}/>
                </div>
            </div>

            
        </div>
    </>)
}

export default BrandEditPage