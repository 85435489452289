import AdminBreadCrumb from "../../../components/admin/admin-bredcrumb.component"
import ProductForm from "./product-form.component"
import ProductService from "../../../services/product.service"
import { toast } from "react-toastify"
import { useNavigate } from "react-router-dom"

const ProductCreatePage = () => {
    let navigate = useNavigate();
    const submitForm = async (data) => {
        try{
            let product_svc = new ProductService()
            let response = await product_svc.create(data);
            toast.success(response.msg)
            navigate("/admin/product")
        } catch(err){
            toast.error(err);
        }
    }
    return (<>
        <div className="container-fluid px-4">
            <AdminBreadCrumb title={"Product Create"} lable="Product" showList={true}/>
            <div className="card mb-4">
                <div className="card-body">
                    <ProductForm 
                        data={{
                            name: null, 
                            description: null, 
                            category_id: null, 
                            price: null,
                            discount: null,
                            is_featured: null,
                            new_arrival: null,
                            brand: null,
                            stock: null,
                            sku: null,
                            seller_id: null,
                            status: null,
                            images: null
                        }} 
                        submitForm={submitForm}/>
                </div>
            </div>

            
        </div>
    </>)
}

export default ProductCreatePage