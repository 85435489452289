import { useCallback, useEffect, useState } from "react";
import { useParams } from "react-router-dom"
import { cat_svc } from "../../services/category.service";
import { Col, Container, Row, Alert } from "react-bootstrap"
import ProductCard from "../../components/home/product-card/product-card.component";

const CategoryDetailList = () => {
    let param = useParams();
    let slug = param.slug;
    let [detail, setDetail] = useState();

    let getCategoryDetail = useCallback(async () => {
        try {
            let response = await cat_svc.getDetailBySlug(slug);
            if (response) {
                setDetail(response.result);
            }
        } catch (error) {

        }
    }, [])
    useEffect(() => {
        getCategoryDetail();
    }, [slug])
    return (<>
        {
            detail ? <>

                <Container>
                    <Row>
                        <Col sm={12} className="bg-light">
                            {detail.cat.name }
                        </Col>
                    </Row>
                    <hr />
                    <Row>
                        {
                            detail.product && detail.product.length >0 ? detail.product.map((item, key) => (
                                <ProductCard key={key} product_detail={item}/>
                            ))
                            : <><Alert variant="warning">Product does not exists on this category.</Alert></>
                        }
                    </Row>
                </Container>
            </> : <><Alert variant="danger">Category not found</Alert></>
        }
    </>)
}

export default CategoryDetailList;