import { useCallback, useEffect, useState } from "react";

import { Col, Container, Image, Row, Card } from "react-bootstrap";
import { NavLink } from "react-router-dom";


import ad1 from "../../assets/images/banner-1.gif";
import cat1 from "../../assets/images/background-1.jpeg";
import cat2 from "../../assets/images/background-3.jpeg";
import noImage from "../../assets/images/no-image.png";

import SliderComponent from "../../components/home/slider/slider.component";
import { banner_svc } from "../../services/banner.service";
import { cat_svc } from "../../services/category.service";
import { product_svc } from "../../services/product.service";
import ProductCard from "../../components/home/product-card/product-card.component";

const HomePage = () => {
    let [banner, setBanner] = useState();
    let [category, setCategory] = useState();
    let [products, setProducts] = useState();

    const getAllBanners = useCallback(async () => {
        try {
            let data = await banner_svc.listAllBanners();
            if (data.status) {
                let images = [];
                data.result.map((item) => {
                    if (item.status === 'active') {
                        images.push(process.env.REACT_APP_IMAGE_URL + item.image)
                    }
                })
                setBanner(images);
            }
        } catch (except) {
            console.error("Banner: ", except);
        }
    }, [])


    const getAllCategories = useCallback(async () => {
        try {
            let data = await cat_svc.listAllCategorys();

            if (data.status) {
                let categoires = data.result.filter((item) => item.status === 'active');
                setCategory(categoires)
            }
        } catch (except) {
            console.error("Banner: ", except);
        }
    }, [])

    const getAllProducts = useCallback(async () => {
        try {
            let data = await product_svc.listAllProducts();
            if (data.status) {
                let active_product = data.result.filter((item) => item.status === 'active');
                setProducts(active_product);
            }
        } catch (except) {
            console.error("Banner: ", except);
        }
    }, [])



    useEffect(() => {
        getAllBanners()
        getAllCategories()
        getAllProducts()
    }, [])

    const showErrImage = (e) => {
        e.target.src = noImage
    }
    return (<>
        <SliderComponent
            images={banner}
            showThumb={false}
            showArrows={false}
            showDots={true}
        />

        <Container className="my-4">
            <Row>
                <Col>
                    <a to="https://google.com">
                        <Image src={ad1} fluid />
                    </a>
                </Col>
            </Row>
        </Container>

        <Container className="mb-4">
            <Row className="bg-light-grey py-2 text-primary">
                <Col>
                    <h4 className="text-center">Categories</h4>
                </Col>
            </Row>
            <Row>
                {
                    category && category.map((item, key) => (
                        <Col sm={6} md={4} lg={2} key={key} className="my-3">
                            <NavLink to={`/category/${item.slug}`}>
                                <Image src={process.env.REACT_APP_IMAGE_URL + item.images[0]} rounded={true} fluid onError={showErrImage} />
                            </NavLink>
                        </Col>
                    ))
                }
            </Row>
        </Container>

        <Container className="mb-4">
            <Row className="bg-light-grey py-2 text-primary">
                <Col>
                    <h4 className="text-center">Products</h4>
                </Col>
            </Row>
            <Row className="my-3">
                {
                    products && products.map((item, key) => (
                        <ProductCard key={key} product_detail={item} />

                    ))
                }
            </Row>
        </Container>
    </>)
}

export default HomePage;