import {configureStore} from "@reduxjs/toolkit"
import UserReducer from "./reducers/user.slicer";
import CartReducer from "./reducers/cart.slicer"

const store = configureStore({
    reducer:{
        user: UserReducer,
        cart: CartReducer
    }
});

export default store;