import AdminBreadCrumb from "../../../components/admin/admin-bredcrumb.component"
import UserForm from "./user-form.component"
import UserService from "../../../services/user.service"
import { toast } from "react-toastify"
import { useNavigate } from "react-router-dom"

const UserCreatePage = () => {
    let navigate = useNavigate();
    const submitForm = async (data) => {
        try{
            let user_svc = new UserService()
            let response = await user_svc.create(data);
            toast.success(response.msg)
            navigate("/admin/user")
        } catch(err){
            toast.error(err);
        }
    }
    return (<>
        <div className="container-fluid px-4">
            <AdminBreadCrumb title={"User Create"} lable="User" showList={true}/>
            <div className="card mb-4">
                <div className="card-body">
                    <UserForm data={{title: null, status: null, image: null}} submitForm={submitForm}/>
                </div>
            </div>

            
        </div>
    </>)
}

export default UserCreatePage