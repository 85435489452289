import AuthTitle from "./component/title.component";
import { Form, Col } from "react-bootstrap";
import {  SubmitCancel } from "../../components/form.component";

import { useFormik } from "formik";
import * as Yup from "yup";
import AuthService from "../../services/auth.service";
import { toast } from "react-toastify";
import {useNavigate} from "react-router-dom"

import {useDispatch} from "react-redux";
import { storeUser } from "../../reducers/user.slicer";

let defaultData = {email: null, password: ""}

const LoginComponent = (props) =>{
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const loginSchema = Yup.object({
        email: Yup.string().email().required("Email is required"),
        password: Yup.string().required().min(8)
    })
    
    const formik = useFormik({
        initialValues: defaultData,
        validationSchema: loginSchema,
        onSubmit: async (values) => {
            try{
                const auth_svc = new AuthService();
                let user = await auth_svc.login(values);
               
                if(user) {
                    dispatch(storeUser({
                        name: user.name,
                        user_id: user._id,
                        email: user.email,
                        role: user.role,
                    }))
                    toast.success("Welcome to user panel!!")
                    navigate("/"+user.role)
                } else {
                    toast.error("Sorry! invalid credential..")
                }
            } catch(except) {
                toast.error(except);
            }
        }
    });

    return (
        <>
            <AuthTitle title="Login Form " />
            <hr />
            <Form onSubmit={formik.handleSubmit}>
            <Form.Group className="row mb-3" >
                <Form.Label className="col-sm-3">Email: </Form.Label>
                <Col sm={9}>
                    <Form.Control
                        name="email"
                        required
                        placeholder="Enter your email..."
                        size="sm"
                        onChange={formik.handleChange}
                        type="email"
                    />
                    <span className="text-danger">{
                        formik.errors.email ?? null
                    }</span>
                </Col>

            </Form.Group>
            <Form.Group className="row mb-3" >
                <Form.Label className="col-sm-3">Password: </Form.Label>
                <Col sm={9}>
                    <Form.Control
                        name="password"
                        required
                        onChange={formik.handleChange}
                        placeholder="Enter your password..."
                        size="sm"
                        type="password"
                    />
                    <span className="text-danger">{formik.errors.password}</span>
                </Col>

            </Form.Group>
                <SubmitCancel />

            </Form>
        </>
    )
}

export default LoginComponent;