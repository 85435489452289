import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from "react-responsive-carousel"

const SliderComponent = ({images, showThumb, showArrows, showDots}) => {
    return (<>
    <Carousel 
        showArrows={showArrows} 
        showThumbs={showThumb} 
        infiniteLoop={true} 
        showDots={showDots}
        autoPlay={true} 
        interval={3000}
    >

            {
                images && images.map((item, key) => (
                <div key={key}>
                    <img src={item} />

                </div>
                ))
            }
            
            </Carousel>
    
    </>)
}
export default SliderComponent;