import { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { toast } from "react-toastify";
import { setCartDetailFromLocalStorage } from "../../reducers/cart.slicer";
import AuthService from "../../services/auth.service";
import { product_svc } from "../../services/product.service";

export const LoginLayout = ({Compontent}) => {

    const [loading, setLoading] = useState(true);

    const checkAccessToken = async () => {
        try{
            const auth_svc = new AuthService();
            let user = await auth_svc.getRequest('/me',true);
            if(user.status){
                setLoading(false)
            } else {
                throw user;
            }
        } catch(except) {
            // user not logged in

            localStorage.removeItem("_at")
            localStorage.removeItem("_au");

            window.location.href= "/login"
            // return <Navigate to="/login"></Navigate>
            // setIsLoggedIn(false);
        }
    }

    useEffect(() => {
        checkAccessToken();
    }, []);

    return loading ? "loading..." : Compontent
        
    
}


const Checkout = () => {
    let cart = useSelector((store) => {
        return store.cart?.cartDetail;
    })
    let [successMsg, setSuccessMsg] = useState()
    let dispatch = useDispatch();
    const checkoutProduct = useCallback(async(cart) => {
        try {
            let response = await product_svc.checkout(cart);
            if(response.status) {
                toast.success(response.msg);
                localStorage.removeItem('cart_detail');
                dispatch(setCartDetailFromLocalStorage([]));
                setSuccessMsg("Your order has been placed successfully. You will shortly notified by the call/email. Thank you for using the service.");
            }
        } catch(err){
            console.log(err)
        }
    },[cart])
    useEffect(() => {
        if(cart.length) {
            checkoutProduct(cart);
        }
    },[cart])
    return <>
    {
        successMsg ? successMsg : ""
    }
    </>
}

export default Checkout;