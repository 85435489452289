import HttpService from "./http-request.service";

class CategoryService extends HttpService {
    create = async (data) => {
        try {
            let formData = new FormData();

            if (data.image) {
                formData.append('image', data.image, data.image.name);
                delete data.image;
            }
            Object.keys(data).forEach((key) => {
                formData.append(key, data[key])
            })
            let response = await this.postRequest('/category', formData, true, true);
            if (response.status) {
                return response
            } else {
                throw response;
            }
        } catch (except) {
            throw except;
        }
    }

    listAllCategorys = async () => {
        try {
            let response = await this.getRequest("/category", false);
            return response
        } catch (err) {
            throw err;
        }
    }

    deleteCategoryById = async (id) => {
        try {
            let del = await this.deleteRequest("/category/" + id, true);
            return del;
        } catch (except) {
            throw except
        }
    }

    getCategoryById = async (id) => {
        try {
            let category_response = await this.getRequest("/category/" + id);
            return category_response;
        } catch (except) {
            throw except
        }
    }

    update = async (data, id) => {
        try {
            let formData = new FormData();
            if (data.image && typeof (data.image) === 'object') {
                formData.append('image', data.image, data.image.name);
                delete data.image;
            }
            Object.keys(data).forEach((key) => {
                formData.append(key, data[key]);
            })
            let status = await this.updateRequest('/category/' + id, formData, true, true);
            if (status) {
                return status;
            } else {
                throw status;
            }
        } catch (except) {
            throw except
        }
    }

    getDetailBySlug = async (slug) => {
        try {
            let response = await this.getRequest('/product/category/' + slug, false);
            if (response.status) {
                return response;
            } else {
                throw response.msg;
            }
        } catch (err) {
            throw err
        }
    }
};
export const cat_svc = new CategoryService();
export default CategoryService;