import axiosInstance from "../config/http-request.config";

class HttpService{
    headers;
    getHeaders = (is_strict, multipart) => {
        this.headers= {};
        let content = {
            "content-type": "application/json"
        };
        if(is_strict) {
            let token = localStorage.getItem("_at");
            if(!token) {
                throw new Error("Token not set")
            }
            content = {
                ...content,
                "authorization": "Bearer "+localStorage.getItem("_at")
            }
        }
        if(multipart) {
            content ={
                ...content,
                'content-type': "multipart/form-data"
            }
        }
        this.headers = {
            headers: content
        }
    }

    postRequest =async (url, data, is_strict=true, multipart=false) => {
        try{
            this.getHeaders(is_strict,multipart);
            let response = await axiosInstance.post(url, data, this.headers);
            if(response){
                return response 
            } else {
                throw response;
            }
        } catch(exccept) {
            throw exccept;
        }
    }

    getRequest = async (url, is_strict=true) => {
        try{
            this.getHeaders(is_strict, false);
            let response = await axiosInstance.get(url, this.headers);
            if(response) {
                return response
            } else {
                throw response
            }
        } catch(except) {
            throw except;
        }
    }

    deleteRequest =async (url, is_strict=true) => {
        try{
            this.getHeaders(is_strict);
            let response = await axiosInstance.delete(url, this.headers);
            if(response){
                return response 
            } else {
                throw response;
            }
        } catch(exccept) {
            throw exccept;
        }
    }

    updateRequest = async (url, data, is_strict = true, multipart=false) => {
        try{
            this.getHeaders(is_strict,multipart);
            let response = await axiosInstance.put(url, data, this.headers);
            if(response){
                return response 
            } else {
                throw response;
            }
        } catch(exccept) {
            throw exccept;
        }
    }
}

export default HttpService;