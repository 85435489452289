import { useState } from "react";
import {Col, Form, Button} from "react-bootstrap";

export const DropDown = ({name, label, options = [], onChange}) => {
    return (
        <Form.Group className="row mb-3">
            <Form.Label htmlFor={name} className="col-sm-3">{label}: </Form.Label>
            <Col sm={9}>
                <Form.Select size="sm" name={name} required onChange={onChange}>
                    {
                        options.map((item, ind) => (
                            <option key={ind} value={item.value}>
                                {item.label}
                            </option>
                        ))
                    }
                </Form.Select>
            </Col>
        </Form.Group>
    );
}





export const EmailComponent = ({setData, name, data}) => {
    let [err, setErr] = useState();
    const handleChange = (e) => {
        let validateState = validateData(e.target.value)
        if(!validateState) {
            setData({
                ...data,
                email: e.target.value
            })
        }
    }
    const validateData = (value) => {
        let msg = (!value) ? "Email is required" : (/^\w+(\w+)*@\w+(\w+)*(\.\w{2,3})+$/.test(value) ? "" : "invalid email")
        setErr({email: msg})
        return msg;
    }
    return (
        <Form.Group className="row mb-3" >
                <Form.Label className="col-sm-3">{name ?? "Email"} : </Form.Label>
                <Col sm={9}>
                    <Form.Control
                        name="email"
                        required
                        onChange={handleChange}
                        placeholder="Enter your email..."
                        size="sm"
                        type="email"
                    />
                    <span className="text-danger">{err?.email}</span>
                </Col>

            </Form.Group>
    )
}




export const PasswordComponent = ({setData, data}) => {
    const handleChange =(e)=> {
        // TODO: Window event trigger
        // TODO: Redux 
        setData({
            ...data,
            password: e.target.value
        })
    }
    return (
        <Form.Group className="row mb-3" >
            <Form.Label className="col-sm-3">Password: </Form.Label>
            <Col sm={9}>
                <Form.Control
                    name="password"
                    required
                    onChange={handleChange}
                    placeholder="Enter your Password..."
                    size="sm"
                    type="password"
                />
            </Col>
        </Form.Group>
    )
}


export const SubmitCancel = ({submitText}) => {
    return (
        <Form.Group className="row mb-3" >
            <Col sm={{offset: 3, span:9}}>
                <Button size="sm" variant="danger" className="me-3" type="reset">
                    <i className="fa fa-trash me-2"></i>
                    Cancel
                </Button>
                <Button size="sm" variant="success" type="submit">
                    <i className="fa fa-paper-plane me-2"></i> 
                    {submitText ?? "Submit"}
                </Button>
            </Col>
        </Form.Group>
    )
}
// export default DropDown