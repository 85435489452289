import { Outlet } from "react-router-dom";
import "@fortawesome/fontawesome-free/css/all.min.css";
import "bootstrap";
import "./admin.css"
import AdminTopNav from "../../components/admin/admin-top-nav.component";
import AdminSidebar from "../../components/admin/admin-sidebar.component";
import AdminFooter from "../../components/admin/admin-footer.component";

const AdminLayout = () => {
    return (<>
        <AdminTopNav />
        <div id="layoutSidenav">
            <AdminSidebar />
            <div id="layoutSidenav_content">
                <main>
                    <Outlet />
                </main>
                <AdminFooter/>
            </div>
        </div>
    </>)
}

export default AdminLayout;