import { Container, Row, Col } from "react-bootstrap";
import LoginComponent from "./login.page";
import RegisterComponent from "./component/register.component";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";

const RegisterPage = () => {
    let [token] = useState(localStorage.getItem('_at'));
    let [loading, setLoading] = useState(true);

    let navigate = useNavigate();
    let user = useSelector((rootstore) => {
       return rootstore?.user?.user?.loggedInuser

    })
    useEffect(() => {
        // let user = JSON.parse(localStorage.getItem("_au"));
        
        if(user) {
            navigate("/"+user.role)
        }
        // console.log(user);
        // toast.success("You are already logged in!!")
        // navigate("/" + user.role)
    
        setLoading(false);
    }, [user])

    return (
        <>
            {
                loading ? "loading..." : <>

                    <Container>
                        <Row>
                            <Col sm={6} md={6}>
                                <RegisterComponent />
                            </Col>

                            <Col sm={6} md={6}>
                                <LoginComponent />
                            </Col>
                        </Row>
                    </Container>
                </>
            }
        </>
    )
}

export default RegisterPage;