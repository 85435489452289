import AdminBreadCrumb from "../../../components/admin/admin-bredcrumb.component"
import CategoryForm from "./category-form.component"
import CategoryService from "../../../services/category.service"
import { toast } from "react-toastify"
import { useNavigate, useParams } from "react-router-dom"
import { useCallback, useEffect, useState } from "react"

const CategoryEditPage = () => {
    let [category, setcategory] = useState();
    let params = useParams();
    let navigate = useNavigate();
    let category_svc = new CategoryService()

    const getCategoryDetail = useCallback(async () => {
        try {

            let category_data = await category_svc.getCategoryById(params.category_id);
            if(category_data){
                
                setcategory(category_data.result)
            } else {
                toast.info("Category does not exists");
                navigate("/admin/category")
            }
        } catch(except) {
            console.log(except);
        }
    }, [])

    useEffect(() => {
        getCategoryDetail()
    }, [getCategoryDetail]);

    const submitForm = async (data) => {
        try{
            let updatedata = {
                title: data.title, 
                status: data.status, 
                image: data.image,
                brands: data.brands
            }

            let response = await category_svc.update(updatedata,params.category_id);
            toast.success(response.msg)
            navigate("/admin/category")
        } catch(err){
            toast.error(err);
        }
    }
    return (<>
        <div className="container-fluid px-4">
            <AdminBreadCrumb title={"Category Edit"} lable="Category" showList={true}/>
            <div className="card mb-4">
                <div className="card-body">
                    <CategoryForm data={category} submitForm={submitForm}/>
                </div>
            </div>

            
        </div>
    </>)
}

export default CategoryEditPage