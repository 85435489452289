import { useCallback, useEffect, useState } from "react"
import {Badge} from "react-bootstrap";

import AdminBreadCrumb from "../../../components/admin/admin-bredcrumb.component"
import {toast} from "react-toastify";
import BannerService from "../../../services/banner.service";
import DataTable from 'react-data-table-component';
import ImagePreview from "../../../components/common/image-preview.component";
import AdminActionButtons from "../../../components/admin/admin-action-buttons.component";


const BannerListPage = () => {
    let [data, setData] = useState();
    let banner_svc = new BannerService();

    const deleteBanner = async (id) => {
        try{
            let del = await banner_svc.deleteBannerById(id);
            if(del.status) {
                toast.success(del.msg);
                getBannerLists()
            }
        } catch(except) {
            toast.warning(except);
        }
    }


    const columns = [
        {
            name: 'Name',
            selector: row => row.name,
            sortable: true
        },
        {
            name: 'Image',
            selector: row => <ImagePreview url={process.env.REACT_APP_IMAGE_URL+row.image}/>,
        },
        {
            name: 'Status',
            selector: row => <Badge bg={row.status === 'active' ? 'success' : 'danger'}>{row.status}</Badge>,
        },
        {
            name: 'Action',
            selector: row => <AdminActionButtons id={row._id} contentType="banner" submitDelete={deleteBanner}/>,
        }
    ];
    


    const getBannerLists = useCallback(async () => {
        
        try{
            let response = await banner_svc.listAllBanners();
            setData(response.result);
        } catch(execpt) {
            toast.error(execpt);
        }
    },[])
    useEffect(() => {
        getBannerLists();
    }, [getBannerLists]);
    return (<>
        <div className="container-fluid px-4">
            <AdminBreadCrumb title={"Banner List"} lable={"banner"} showAdd={true}/>
            
            <div className="card mb-4">
                <div className="card-body">
                    <DataTable
                        columns={columns}
                        data={data}
                        pagination
                    />
                </div>
            </div>

            
        </div>

    </>)
}

export default BannerListPage