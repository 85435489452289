import AdminBreadCrumb from "../../../components/admin/admin-bredcrumb.component"
import CategoryForm from "./category-form.component"
import CategoryService from "../../../services/category.service"
import { toast } from "react-toastify"
import { useNavigate } from "react-router-dom"

const CategoryCreatePage = () => {
    let navigate = useNavigate();
    const submitForm = async (data) => {
        try{
            let category_svc = new CategoryService()
            let response = await category_svc.create(data);
            toast.success(response.msg)
            navigate("/admin/category")
        } catch(err){
            toast.error(err);
        }
    }
    return (<>
        <div className="container-fluid px-4">
            <AdminBreadCrumb title={"Category Create"} lable="Category" showList={true}/>
            <div className="card mb-4">
                <div className="card-body">
                    <CategoryForm data={{title: null, status: null, image: null, brands: null}} submitForm={submitForm}/>
                </div>
            </div>

            
        </div>
    </>)
}

export default CategoryCreatePage