import { useFormik } from "formik";
import {Form, Col} from "react-bootstrap";
import { SubmitCancel } from "../../../components/form.component";
import * as Yup from "yup";
import { useEffect } from "react";

const BrandForm = ({data, submitForm}) => {
    const brandValidate = Yup.object({
        title: Yup.string().nullable().required("Title is required").min(2, "Title should be alteast 3 character long"),
        status: Yup.string().nullable().required("Status is required"),
        image: Yup.object().nullable()
    })
    const formik = useFormik({
        initialValues: data,
        validationSchema: brandValidate,
        onSubmit: (values) => {
            submitForm(values)
        }
    })

    useEffect(() => {
        if(data) {
            formik.setValues({
                ...data
            })
        }
    },[data])
    
    return (<>

        <Form onSubmit={formik.handleSubmit}>
            <Form.Group className="row mb-3">
                <Col as="label" className="form-label" sm={3}>Title: </Col>
                <Col sm={9}>
                    <Form.Control
                        type="text"
                        value={formik.values?.title}
                        size="sm"
                        name="title"
                        onChange={formik.handleChange}
                        placeholder="Enter Brand Name..."
                    />
                    <span className="text-danger">{formik?.errors?.title}</span>
                </Col>
            </Form.Group>

            <Form.Group className="row mb-3">
                <Col as="label" className="form-label" sm={3}>Status: </Col>
                <Col sm={9}>
                    <Form.Select size="sm" name="status" value={formik.values?.status} onChange={formik.handleChange} >
                        <option selected value={null}>--Select Any One--</option>
                        <option value={'active'}>Active</option>
                        <option value={"inactive"}>In-active</option>
                    </Form.Select>
                    <span className="text-danger">{formik?.errors?.status}</span>
                </Col>
            </Form.Group>

            <Form.Group className="row mb-3">
                <Col as="label" className="form-label" sm={3}>File: </Col>
                <Col sm={6}>
                    <Form.Control
                        type="file"
                        size="sm"
                        accept="image/*"
                        name="image"
                        onChange={(e) => {
                            // 
                            formik.setValues({
                                ...formik.values,
                                image: e.target.files[0]
                            })
                        }}
                    />
                    <span className="text-danger">{formik?.errors?.image}</span>
                </Col>
                <Col>
                        {
                            formik?.values?.image ? 
                            <img src={(typeof(formik.values.image) === 'object' ? URL.createObjectURL(formik.values.image) : process.env.REACT_APP_IMAGE_URL+formik.values.image)} className="img img-fluid" /> 
                            : <></>
                        }
                </Col>
            </Form.Group>


            <SubmitCancel />
        </Form>
    </>)
}

export default BrandForm;