import { NavLink } from "react-router-dom";
import Swal from "sweetalert2";

const AdminActionButtons = ({id, contentType, submitDelete}) => {
    const handleDelete = (e) => {
        e.preventDefault()
        Swal.fire({
            title: 'Are you sure?',
            text: "You won't be able to revert this!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, delete it!'
          }).then((result) => {
            if (result.isConfirmed) {
                submitDelete(id)
            }
          })
    }
    return (<>
        <NavLink to={`/admin/${contentType}/${id}/edit`} className="btn btn-sm btn-success rounded-circle me-2">
            <i className="fa fa-pen"></i>
        </NavLink>
        <a href={`/admin/${contentType}`} onClick={handleDelete} className="btn btn-sm btn-danger rounded-circle" >
            <i className="fa fa-trash"></i>
        </a>
    </>)
}

export default AdminActionButtons;