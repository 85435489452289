import AdminBreadCrumb from "../../../components/admin/admin-bredcrumb.component"
import UserForm from "./user-form.component"
import UserService from "../../../services/user.service"
import { toast } from "react-toastify"
import { useNavigate, useParams } from "react-router-dom"
import { useCallback, useEffect, useState } from "react"

const UserEditPage = () => {
    let [user, setuser] = useState();
    let params = useParams();
    let navigate = useNavigate();
    let user_svc = new UserService()

    const getUserDetail = useCallback(async () => {
        try {

            let user_data = await user_svc.getUserById(params.user_id);
            if(user_data){
                setuser(user_data.result)
            } else {
                toast.info("User does not exists");
                navigate("/admin/user")
            }
        } catch(except) {
            console.log(except);
        }
    }, [])

    useEffect(() => {
        getUserDetail()
    }, [getUserDetail]);

    const submitForm = async (data) => {
        try{
            let updatedata = {
                title: data.title, 
                status: data.status, 
                image: data.image
            }

            let response = await user_svc.update(updatedata,params.user_id);
            toast.success(response.msg)
            navigate("/admin/user")
        } catch(err){
            toast.error(err);
        }
    }
    return (<>
        <div className="container-fluid px-4">
            <AdminBreadCrumb title={"User Edit"} lable="User" showList={true}/>
            <div className="card mb-4">
                <div className="card-body">
                    <UserForm data={user} submitForm={submitForm}/>
                </div>
            </div>

            
        </div>
    </>)
}

export default UserEditPage