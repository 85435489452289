import { createSlice } from "@reduxjs/toolkit";

const UserSlicer = createSlice({
    name: "user",
    initialState: {
        loggedInUser: null
    },
    reducers: {
        storeUser: (state, action) => {
            state.loggedInUser = action.payload;
        }
    }
})

export const {storeUser} = UserSlicer.actions;
export default UserSlicer.reducer;