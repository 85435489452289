import AdminLayout from "./layout.page";
import AdminDashboard from "./dashboard/admin-dashboard.page";

import BrandListPage from "./brand/brand-list.page";
import BrandCreatePage from "./brand/brand-create.page";
import BrandEditPage from "./brand/brand-edit.page";


import BannerListPage from "./banner/banner-list.page";
import BannerCreatePage from "./banner/banner-create.page";
import BannerEditPage from "./banner/banner-edit.page";

import CategoryListPage from "./category/category-list.page";
import CategoryCreatePage from "./category/category-create.page";
import CategoryEditPage from "./category/category-edit.page";


import UserListPage from "./user/user-list.page";
import UserCreatePage from "./user/user-create.page";
import UserEditPage from "./user/user-edit.page";


import ProductListPage from "./product/product-list.page";
import ProductCreatePage from "./product/product-create.page";
import ProductEditPage from "./product/product-edit.page";

const Admin ={
    AdminDashboard, 
    AdminLayout,
    BrandListPage,
    BrandCreatePage,
    BrandEditPage,
    BannerListPage,
    BannerCreatePage,
    BannerEditPage,
    CategoryListPage,
    CategoryCreatePage,
    CategoryEditPage,
    UserListPage,
    UserCreatePage,
    UserEditPage,
    ProductListPage,
    ProductCreatePage,
    ProductEditPage
}

export default Admin;