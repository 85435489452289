import { useEffect, useState } from "react";
import { Container, Navbar, Nav } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { NavLink, useNavigate } from "react-router-dom";
import { setCartDetailFromLocalStorage } from "../../reducers/cart.slicer";
import { storeUser } from "../../reducers/user.slicer";
import AuthService from "../../services/auth.service";

const NavigationMenu = () => {
    // const [user, setUser] = useState(JSON.parse(localStorage.getItem("_au")));
    const navigate = useNavigate();
    const dispath = useDispatch();

    const logout = (e) => {
        e.preventDefault()
        localStorage.removeItem('_at')
        localStorage.removeItem('_au')
        // setUser(null);
        return navigate("/login");
    }

    const setUser = async () => {
        const auth_svc = new AuthService();
        let user = await auth_svc.getRequest('/me',true);
        dispath(storeUser({name: user.result.name,
            user_id: user.result._id,
            email: user.result.email,
            role: user.result.role,}))
    }

    const setCart = async () => {
        let cart_detail = JSON.parse(localStorage.getItem('cart_detail')) ?? [];
        dispath(setCartDetailFromLocalStorage(cart_detail));
    }
    useEffect(() => {
        setCart();
        setUser()
    }, [])

    let user = useSelector((root) => {
        if(root.user?.loggedInUser) {
            return root.user?.loggedInUser
        }
    })

    let counter = useSelector((root) => {
        let total = 0

        if(root.cart.cartDetail) {
            root.cart.cartDetail.map((item) => {
                total += Number(item.qty);
            })
        }
        return total;
    })


    

    return (<Navbar bg="dark" variant="dark">
    <Container>
        <NavLink className="navbar-brand" to="/">Ecom Title</NavLink>
        <Nav className="me-auto">
            <NavLink className="nav-link" to="/">Home</NavLink>
            <NavLink className="nav-link" to="/featured">Featured Product</NavLink>
            <NavLink className="nav-link" to="/new-arrivals">New Product</NavLink>
            
            {
                user ? <></> : <><NavLink className="nav-link" to="/register">Register</NavLink>
                <NavLink className="nav-link" to="/login">Login</NavLink></>
            }
        </Nav>
        <Nav>
            <NavLink className={"nav-link"} to="/cart">Cart ({counter})</NavLink>
        {
            user ? 
            <>
                <NavLink className={"nav-link"} to={`/${user?.role}`}>{
                    user?.name
                    }</NavLink>
                <NavLink className={"nav-link"} to="/login" onClick={logout}>Logout</NavLink>
                </>
                : <></>
        }
        </Nav> 
        {/* <Nav className="me-auto">
            <Form>
                <Form.Control type="search" name="q"></Form.Control>
            </Form>
        </Nav> */}
    </Container>
</Navbar>)
}
export default NavigationMenu;