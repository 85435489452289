import HttpService from "./http-request.service";


class AuthService extends HttpService{
    login = async (data) => {
        try{
            let response = await this.postRequest('/login', data, false);
            if(response) {
                localStorage.setItem("_at",response.result.token)
                // localStorage.setItem("_au", JSON.stringify({
                //     name: response.result.detail.name,
                //     user_id: response.result.detail._id,
                //     email: response.result.detail.email,
                //     role: response.result.detail.role,
                // }))
                return response.result.detail;
            } else {
                throw new Error("Invalid Credentials")
            }
        } catch(except) {
            throw except
        }
    }
}
export default AuthService;