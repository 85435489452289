import { useSelector } from "react-redux";
import { NavLink } from "react-router-dom";

const AdminSidebar = () => {
    let user = useSelector((rootStore) => {
        return rootStore.user.loggedInUser;
    })
    return (<>
        <div id="layoutSidenav_nav">
                <nav className="sb-sidenav accordion sb-sidenav-dark" id="sidenavAccordion">
                    <div className="sb-sidenav-menu">
                        <div className="nav">
                            <div className="sb-sidenav-menu-heading">Core</div>
                            <NavLink className="nav-link" to="/admin">
                                <div className="sb-nav-link-icon"><i className="fas fa-tachometer-alt"></i></div>
                                Dashboard
                            </NavLink>
                            
                            <NavLink className="nav-link" to="/admin/brand">
                                <div className="sb-nav-link-icon"><i className="fas fa-tachometer-alt"></i></div>
                                Brand Management
                            </NavLink>

                            <NavLink className="nav-link" to="/admin/banner">
                                <div className="sb-nav-link-icon"><i className="fas fa-tachometer-alt"></i></div>
                                Banner Management
                            </NavLink>
                            
                            <NavLink className="nav-link" to="/admin/category">
                                <div className="sb-nav-link-icon"><i className="fas fa-tachometer-alt"></i></div>
                                Category Management
                            </NavLink>

                            <NavLink className="nav-link" to="/admin/user">
                                <div className="sb-nav-link-icon"><i className="fas fa-tachometer-alt"></i></div>
                                User Management
                            </NavLink>

                            <NavLink className="nav-link" to="/admin/product">
                                <div className="sb-nav-link-icon"><i className="fas fa-tachometer-alt"></i></div>
                                Product Management
                            </NavLink>

                            <a className="nav-link" href="index.html">
                                <div className="sb-nav-link-icon"><i className="fas fa-tachometer-alt"></i></div>
                                Order Management
                            </a>

                            <a className="nav-link" href="index.html">
                                <div className="sb-nav-link-icon"><i className="fas fa-tachometer-alt"></i></div>
                                Transaction Management
                            </a>

                            <a className="nav-link" href="index.html">
                                <div className="sb-nav-link-icon"><i className="fas fa-tachometer-alt"></i></div>
                                Review Management
                            </a>
                            
                            <a className="nav-link" href="index.html">
                                <div className="sb-nav-link-icon"><i className="fas fa-tachometer-alt"></i></div>
                                Offer Management
                            </a>
                            
                            <a className="nav-link" href="index.html">
                                <div className="sb-nav-link-icon"><i className="fas fa-tachometer-alt"></i></div>
                                Blog Management
                            </a>
                            
                        </div>
                    </div>
                    <div className="sb-sidenav-footer">
                        <div className="small">Logged in as:</div>
                        { user?.name}
                    </div>
                </nav>
            </div>
    </>)
}

export default AdminSidebar;