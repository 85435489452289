import { useCallback, useEffect, useState } from "react";
import { Container, Row, Col } from "react-bootstrap";
import { useSelector } from "react-redux";
import { product_svc } from "../../services/product.service";
import {NavLink} from "react-router-dom"
const CartPage = () => {
    const [cartDetail, setCartDetail] = useState();
    const [totalAmt, setTotalAmt] = useState(0);

    let cart = useSelector((root) => {
        return root.cart?.cartDetail
    })

    let getCartDetail = useCallback(async() =>{
        let response = await product_svc.getCartDetail(cart);
        if(response.result) {
            let total = 0
            response.result?.map((cart_item) => {
                total += cart_item.qty * cart_item.price;
            })
            setTotalAmt(total)
            setCartDetail(response.result)
        }
    })
    
    useEffect(() => {
        if(cart.length > 0){
            getCartDetail();
        }
    }, [cart])
    
    return (<>
        <Container className="my-5">
            <Row>
                <Col>
                    <h1 className="text-center">Cart Detail</h1>
                    <hr />
                </Col>
            </Row>
            <Row>
                <Col>
                    <table className="table table-sm table-hover table-striped">
                        <thead className="table-dark">
                            <tr>
                                <th>Product name</th>
                                <th>Product Price</th>
                                <th>Product Qty</th>
                                <th>Product Amount</th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                cartDetail && cartDetail.map((cart_item, ind) => (
                                    <tr key={ind}>
                                        <td>{cart_item.name}</td>
                                        <td>{cart_item.price}</td>
                                        <td>{cart_item.qty}</td>
                                        <td>{cart_item.qty * cart_item.price}</td>
                                    </tr>
                                ))
                            }
                        </tbody>
                        <tfoot>
                            <tr>
                                <th colSpan={3} style={{textAlign: 'right'}}>Total: </th>
                                <th>{totalAmt}</th>
                            </tr>
                        </tfoot>
                    </table>
                    <NavLink to="/checkout" className="btn btn-sm btn-success mt-5">Checkout</NavLink>
                </Col>
            </Row>
        </Container>
    </>)
}
export default CartPage;