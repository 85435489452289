import AuthTitle from "./title.component";
import {Form,Col} from "react-bootstrap"
import { EmailComponent, PasswordComponent, DropDown, SubmitCancel } from "../../../components/form.component";
import { useState } from "react";
const defaultData = {
    name: "",
    email: "",
    password: "",
    confirm_password: "",
    role: "",
    status: "",
    image: ""
};

const RegisterComponent = () => {
    const [data, setData] = useState(defaultData);

    const [err, setErr] = useState(defaultData);


    const handleChange = (e) => {
        setData({
            ...data, 
            [e.target.name]: e.target.value
        })
        validateData(e.target.name, e.target.type, e.target.value);

    }

    const validateData = (name, type, value) => {
        let msg = null;

        switch(name){
            case "name":
                msg = (!value) ? "Name is required" : "";
                break;
            case "email":
                msg = (!value) ? "Email is required" : (/^\w+(\w+)*@\w+(\w+)*(\.\w{2,3})+$/.test(value) ? "" : "invalid email")
                break;
            case "password":
                msg = (!value) ? "Passowrd is required" : (value.length < 8 ? "Password must be atleast 8 character long" : "");
                break;
            case "confirm_password":
                msg = (!value) ? "Passowrd is required" : (data.password !== value ? "Password does not match" : '');
                break;
            case "role":
                msg = (!value) ? "Role is required" : (['admin','seller','customer'].includes(value) ? "" : 'Invalid Role');
                break;
            case "status":
                msg = (!value) ? "Status is required" : (['active','inactive'].includes(value) ? "" : 'Invalid Status');
                break;
            default: 
                break;
        }
            setErr({
                ...err,
                [name]: msg
            })
        
    }

    console.log("Error: ", err)
    return (<>
        <AuthTitle title="Register Form " />
        <hr />
        <Form>
            <Form.Group className="row mb-3" >
                <Form.Label className="col-sm-3">Name: </Form.Label>
                <Col sm={9}>
                    <Form.Control
                        name="name"
                        required
                        placeholder="Enter your name..."
                        size="sm"
                        onChange={handleChange}
                    />
                    <span className="text-danger">{err.name && err.name}</span>
                </Col>

            </Form.Group>


            <EmailComponent name="Email Address" setData={setData} data={data} />

            <PasswordComponent setData={setData} data={data} />


            <Form.Group className="row mb-3" >
                <Form.Label className="col-sm-3">Re-Password: </Form.Label>
                <Col sm={9}>
                    <Form.Control
                        name="confirm_password"
                        required
                        placeholder="Enter your Password again..."
                        size="sm"
                        type="password"
                        onChange={handleChange}
                    />
                </Col>
            </Form.Group>

            <DropDown
                label="Role"
                name="role"
                onChange={handleChange}
                options={[{ label: "Admin", value: "admin" },
                { label: "Customer", value: "customer" },
                { label: "Seller", value: "seller" }]}
            />


            <DropDown
                label="Status"
                name="status"
                onChange={handleChange}
                options={[
                    { label: "Active", value: "active" },
                    { label: "In-Active", value: "inactive" }
                ]}
            />

            <Form.Group className="row mb-3" >
                <Form.Label className="col-sm-3">Image: </Form.Label>
                <Col sm={9}>
                    <Form.Control
                        name="image"
                        size="sm"
                        type="file"
                    />
                </Col>
            </Form.Group>

            <SubmitCancel />

        </Form>

    </>)
}

export default RegisterComponent;