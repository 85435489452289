import AdminBreadCrumb from "../../../components/admin/admin-bredcrumb.component"
import BannerForm from "./banner-form.component"
import BannerService from "../../../services/banner.service"
import { toast } from "react-toastify"
import { useNavigate } from "react-router-dom"

const BannerCreatePage = () => {
    let navigate = useNavigate();
    const submitForm = async (data) => {
        try{
            let banner_svc = new BannerService()
            let response = await banner_svc.create(data);
            toast.success(response.msg)
            navigate("/admin/banner")
        } catch(err){
            toast.error(err);
        }
    }
    return (<>
        <div className="container-fluid px-4">
            <AdminBreadCrumb title={"Banner Create"} lable="Banner" showList={true}/>
            <div className="card mb-4">
                <div className="card-body">
                    <BannerForm data={{title: null, status: null, image: null}} submitForm={submitForm}/>
                </div>
            </div>

            
        </div>
    </>)
}

export default BannerCreatePage