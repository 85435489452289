import { useCallback, useEffect, useState } from "react"
import {Badge} from "react-bootstrap";

import AdminBreadCrumb from "../../../components/admin/admin-bredcrumb.component"
import {toast} from "react-toastify";
import ProductService from "../../../services/product.service";
import DataTable from 'react-data-table-component';
import ImagePreview from "../../../components/common/image-preview.component";
import AdminActionButtons from "../../../components/admin/admin-action-buttons.component";


const ProductListPage = () => {
    let [data, setData] = useState();
    let product_svc = new ProductService();

    const deleteProduct = async (id) => {
        try{
            let del = await product_svc.deleteProductById(id);
            if(del.status) {
                toast.success(del.msg);
                getProductLists()
            }
        } catch(except) {
            toast.warning(except);
        }
    }


    const columns = [
        {
            name: 'Name',
            selector: row => row.name,
            sortable: true
        },
        {
            name: 'Category',
            selector: row => row.category_id.name,
            sortable: true
        },
        
        {
            name: 'Price',
            selector: row => "Npr. "+row.price,
            sortable: true
        },
        {
            name: 'After Discount',
            selector: row => "Npr. "+row.actual_price,
            sortable: true
        },
        
        {
            name: 'Status',
            selector: row => <Badge bg={row.status === 'active' ? 'success' : 'danger'}>{row.status}</Badge>,
        },
        {
            name: 'Action',
            selector: row => <AdminActionButtons id={row._id} contentType="product" submitDelete={deleteProduct}/>,
        }
    ];
    


    const getProductLists = useCallback(async () => {
        
        try{
            let response = await product_svc.listAllProducts();
            setData(response.result);
        } catch(execpt) {
            toast.error(execpt);
        }
    }, [])
    useEffect(() => {
        getProductLists();
    }, [getProductLists]);
    return (<>
        <div className="container-fluid px-4">
            <AdminBreadCrumb title={"Product List"} lable={"product"} showAdd={true}/>
            
            <div className="card mb-4">
                <div className="card-body">
                    <DataTable
                        columns={columns}
                        data={data}
                        pagination
                    />
                </div>
            </div>

            
        </div>

    </>)
}

export default ProductListPage