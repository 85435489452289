import { Container, Row, Col, Form, Button } from "react-bootstrap";
import "./home.layout.css";
import { Outlet, NavLink } from "react-router-dom";
import NavigationMenu from "../../components/home/header.component";

const HomeLayout = () => {
    return (<>
        <NavigationMenu />
        <Outlet />
        <footer className="bg-dark" style={{minHeight: "200px"}}>
            <Container className="text-light mt-3 pt-3">
                <Row>
                    <Col sm={12} md={4}>
                        <h4 className="text-center">About Us</h4>
                        <hr />
                        <p>
                            It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters.
                        </p>
                    </Col>

                    <Col sm={12} md={4}>
                        <h4 className="text-center">
                            Useful Links
                        </h4>
                        <hr />
                        <ul style={{listStyle: "none"}}>
                            <li>
                                <NavLink to="/" className={"footer-link"}>
                                    Home
                                </NavLink>
                            </li>
                            <li><NavLink to="/about-us" className={"footer-link"}>
                                    About Us
                                </NavLink></li>
                            <li><NavLink to="/contact-us" className={"footer-link"}>
                                    Contact Us
                                </NavLink></li>
                            <li>
                            <NavLink to="/return-policy" className={"footer-link"}>
                                    Return Policy
                                </NavLink>
                            </li>
                        </ul>
                    </Col>
                    
                    <Col sm={12} md={4}>
                        <h4 className="text-center">
                            Connect With us
                        </h4>
                        <hr />
                        <Form>
                            <Form.Control 
                                type="email"
                                name="email"
                                placeholder="Subscribe with us..."
                                size="sm"
                                className="mb-3"
                            />
                            <Button variant="success" type="submit">
                                Subscribe Newsletter
                            </Button>
                        </Form>
                    </Col>
                </Row>
            </Container>
        </footer>
    </>)
}

export default HomeLayout;