import { useFormik } from "formik";
import {Form, Col} from "react-bootstrap";
import { SubmitCancel } from "../../../components/form.component";
import * as Yup from "yup";
import { useCallback, useEffect, useState } from "react";
import BrandService from "../../../services/brand.service";
import CategoryService from "../../../services/category.service";
import UserService from "../../../services/user.service";

import Select from 'react-select'
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';

const ProductForm = ({data, submitForm}) => {
    const [brands, setBrands] = useState();
    const [category, setCategory] = useState();
    const [seller, setSeller] = useState();
    const [sel_cat, setSelCat] = useState();
    const [sel_brand, setBrand] = useState();

    const brand_svc = new BrandService()
    const cat_svc = new CategoryService();
    const user_svc = new UserService();

    const productValidate = Yup.object({
        name: Yup.string().nullable().required(), 
        description:  Yup.string().nullable().required(),
        category_id:  Yup.object().nullable().required(), 
        price:  Yup.number().nullable().required().min(1), 
        discount:  Yup.number().nullable().nullable().min(0).max(100), 
        is_featured:  Yup.boolean().nullable().default(false), 
        new_arrival: Yup.boolean().nullable().default(false), 
        brand: Yup.object().nullable(), 
        stock: Yup.number().nullable().min(1), 
        sku: Yup.string().nullable(), 
        seller_id:Yup.object().nullable(), 
        status: Yup.string().nullable(), 
        images: Yup.array().nullable()
    })

    const formik = useFormik({
        initialValues: data,
        validationSchema: productValidate,
        onSubmit: (values) => {
            
            values.category_id = sel_cat?.value
            values.brand = sel_brand?.value;
            values.seller_id = values.seller_id?.value
            submitForm(values);
        }
    })

    const listAllBrands = useCallback(async() => {
        try{
            let response = await brand_svc.listAllBrands();
            if(response) {
                let options = response.result.map((item) => {
                    return {
                        value: item._id,
                        label: item.title
                    };
                })
                setBrands(options);
            }
        } catch(except) {
            // handle 
            console.error(except);
        }
    })

    const listAllCategories = useCallback(async() => {
        try{
            let response = await cat_svc.listAllCategorys();
            if(response) {
                let options = response.result.map((item) => {
                    return {
                        value: item._id,
                        label: item.name
                    };
                })
                setCategory(options);
            }
        } catch(except) {
            // handle 
            console.error(except);
        }
    })

    const listAllSellers = useCallback(async() => {
        try{
            let response = await user_svc.listAllSellers();
            if(response.result) {

                let options = response.result.map((item) => {
                    return {
                        value: item._id,
                        label: item.name
                    };
                })
                setSeller(options);
            }
        } catch(except) {
            // handle 
            console.error(except);
        }
    })

    
    useEffect(() => {
        listAllCategories()
        listAllBrands();
        listAllSellers();

    },[])

    useEffect(() => {
        if(data){
            
            let sel_category = null;
            if(data.category_id) {
                sel_category = {
                    value: data.category_id._id,
                    label: data.category_id.name
                }
            }
            setSelCat(sel_category)
            let sel_brand = null;
            if(data.brand){
                sel_brand ={
                    value: data.brand._id,
                    label: data.brand.title
                }
            }
            setBrand(sel_brand);
            
            formik.setValues({
                ...data
            })
        }
    }, [data])

    return (<>

        <Form onSubmit={formik.handleSubmit}>
            <Form.Group className="row mb-3">
                <Col as="label" className="form-label" sm={3}>Name: </Col>
                <Col sm={9}>
                    <Form.Control
                        type="text"
                        value={formik.values?.name}
                        size="sm"
                        name="name"
                        onChange={formik.handleChange}
                        placeholder="Enter Product Name..."
                    />
                    <span className="text-danger">{formik?.errors?.name}</span>
                </Col>
            </Form.Group>

            <Form.Group className="row mb-3">
                <Col as="label" className="form-label" sm={3}>Description: </Col>
                <Col sm={9}>
                    <CKEditor
                        editor={ ClassicEditor }
                        data={formik.values?.description}
                        onChange={ ( event, editor ) => {
                            const description = editor.getData();
                            if(data.description){
                                formik.setValues({
                                    ...data,
                                    description: description
                                })
                            } else {
                                formik.setValues({
                                    ...formik.values, 
                                    description: description
                                })
                            }
                            
                        } }
                    />
                    
                    <span className="text-danger">{formik?.errors?.description}</span>
                </Col>
            </Form.Group>

            <Form.Group className="row mb-3">
                <Col as="label" className="form-label" sm={3}>Category: </Col>
                <Col sm={9}>
                    {
                        category && <>
                        <Select options={category} value={sel_cat} onChange={(e) => {
                            formik.setValues({
                                ...formik.values,
                                category_id: e
                            })
                            setSelCat(e);
                        }}/>
                        <span className="text-danger">{formik?.errors?.category_id}</span>
                        
                        </>
                    }
                </Col>
            </Form.Group>

            <Form.Group className="row mb-3">
                <Col as="label" className="form-label" sm={3}>Price (Npr. ): </Col>
                <Col sm={9}>
                    <Form.Control
                        type="number"
                        value={formik.values?.price}
                        size="sm"
                        name="price"
                        min={1}
                        required
                        onChange={formik.handleChange}
                        placeholder="Enter Product Price..."
                    />
                    <span className="text-danger">{formik?.errors?.price}</span>
                </Col>
            </Form.Group>

            <Form.Group className="row mb-3">
                <Col as="label" className="form-label" sm={3}>Discount (in %): </Col>
                <Col sm={9}>
                    <Form.Control
                        type="number"
                        value={formik.values?.discount}
                        size="sm"
                        name="discount"
                        min={0}
                        max={100}
                        onChange={formik.handleChange}
                        placeholder="Enter Product discount..."
                    />
                    <span className="text-danger">{formik?.errors?.discount}</span>
                </Col>
            </Form.Group>

            <Form.Group className="row mb-3">
                <Col as="label" className="form-label" sm={3}>Featured : </Col>
                <Col sm={9}>
                    <Form.Check 
                        type="switch"
                        id="custom-switch"
                        label="Yes"
                        name="is_featured"
                        checked={formik.values?.is_featured ? true : false}
                        onChange={(e) => {
                            formik.setValues({
                                ...formik.values,
                                is_featured: e.target.checked
                            })
                        }}
                    />            
                    <span className="text-danger">{formik?.errors?.is_featured}</span>
                </Col>
            </Form.Group>

            <Form.Group className="row mb-3">
                <Col as="label" className="form-label" sm={3}>New Arrival : </Col>
                <Col sm={9}>
                    <Form.Check 
                        type="switch"
                        id="custom-switch"
                        label="Yes"
                        name="new_arrival"
                        checked={formik.values?.new_arrival ? true : false}
                        onChange={(e) => {
                            formik.setValues({
                                ...formik.values,
                                new_arrival: e.target.checked
                            })
                        }}
                    />            
                    <span className="text-danger">{formik?.errors?.new_arrival}</span>
                </Col>
            </Form.Group>

            <Form.Group className="row mb-3">
                <Col as="label" className="form-label" sm={3}>Brand: </Col>
                <Col sm={9}>
                    {
                        brands && <>
                        <Select options={brands} value={sel_brand} onChange={(e) => {
                            formik.setValues({
                                ...formik.values,
                                brand: e
                            })
                            setBrand(e);
                        }}/>
                        <span className="text-danger">{formik?.errors?.brand}</span>
                        
                        </>
                    }
                </Col>
            </Form.Group>

            <Form.Group className="row mb-3">
                <Col as="label" className="form-label" sm={3}>Stock(in Units): </Col>
                <Col sm={9}>
                    <Form.Control
                        type="number"
                        value={formik.values?.stock}
                        size="sm"
                        name="stock"
                        min={1}
                        onChange={formik.handleChange}
                        placeholder="Enter Product stock..."
                    />
                    <span className="text-danger">{formik?.errors?.stock}</span>
                </Col>
            </Form.Group>


            <Form.Group className="row mb-3">
                <Col as="label" className="form-label" sm={3}>SKU: </Col>
                <Col sm={9}>
                    <Form.Control
                        type="text"
                        value={formik.values?.sku}
                        size="sm"
                        name="sku"
                        min={1}
                        onChange={formik.handleChange}
                        placeholder="Enter Product SKU..."
                    />
                    <span className="text-danger">{formik?.errors?.sku}</span>
                </Col>
            </Form.Group>


            <Form.Group className="row mb-3">
                <Col as="label" className="form-label" sm={3}>Seller: </Col>
                <Col sm={9}>
                    <Select size="sm" options={seller ?? []} value={formik.values?.seller_id} onChange={(e) => {
                        formik.setValues({
                            ...formik.values,
                            seller_id: e
                        })
                    }}/>
                    <span className="text-danger">{formik?.errors?.seller_id}</span>
                        
                        
                    
                </Col>
            </Form.Group>

            <Form.Group className="row mb-3">
                <Col as="label" className="form-label" sm={3}>Status: </Col>
                <Col sm={9}>
                    <Form.Select size="sm" name="status" value={formik.values?.status} onChange={formik.handleChange} >
                        <option>--Select Any One--</option>
                        <option value={'active'}>Active</option>
                        <option value={"inactive"}>In-active</option>
                    </Form.Select>
                    <span className="text-danger">{formik?.errors?.status}</span>
                </Col>
            </Form.Group>

            <Form.Group className="row mb-3">
                <Col as="label" className="form-label" sm={3}>Images: </Col>
                <Col sm={6}>
                    <Form.Control
                        type="file"
                        size="sm"
                        accept="image/*"
                        name="image"
                        multiple={true}
                        onChange={(e) => {
                            // 
                            formik.setValues({
                                ...formik.values,
                                images: Object.values(e.target.files)
                            })
                        }}
                    />
                    <span className="text-danger">{formik?.errors?.images}</span>
                </Col>
                <Col>
                    
                </Col>
            </Form.Group>


            <SubmitCancel />
        </Form>
    </>)
}

export default ProductForm;