import HttpService from "./http-request.service";

class ProductService extends HttpService{
    create = async (data) => {
        try{
            let formData = new FormData();
            
            if(data.images) {
                data.images.forEach((item) => {
                    formData.append('images', item, item.name);
                })
                delete data.images;
            }
            Object.keys(data).forEach((key) => {
                formData.append(key, data[key])
            })
            let response = await this.postRequest('/product', formData,true, true);
            if(response.status) {
                return response
            } else {
                throw response;
            }
        } catch(except) {
            throw except;
        }
    }

    listAllProducts = async () => {
        try {
            let response = await this.getRequest("/product", false);
            return response
        } catch(err) {
            throw err;
        }
    }

    deleteProductById = async(id) => {
        try {
            let del = await this.deleteRequest("/product/"+id, true);
            return  del;
        } catch(except) {
            throw except
        }
    }

    getProductById = async (id) => {
        try {
            let product_response = await this.getRequest("/product/"+id);
            return  product_response;
        } catch(except) {
            throw except
        }
    }

    update = async (data, id) => {
        try {
            let formData = new FormData();
            if(data.images && typeof(data.images) === 'object') {
                data.images.forEach((item) => {
                    formData.append('images', item, item.name);
                })
                delete data.images;
            }
            Object.keys(data).forEach((key) => {
                formData.append(key, data[key]);
            })
            let status = await this.updateRequest('/product/'+id, formData, true, true);
            if(status) {
                return status;
            } else {
                throw status;
            }
        } catch(except) {
            throw except
        }
    }
    getProductBySlug = async (slug) => {
        try {
            let response = await this.getRequest('/product/'+slug, false);
            if(response.status) {
                return response
            } else {
                throw response.msg;
            }
        } catch(error) {
            throw error
        }
    }

    getCartDetail = async (cart) => {
        try {
            let response = await this.postRequest('/product/cart-detail', {cart_detail: cart}, false, false)
            return response;
        } catch(err){
            throw err
        }
    }

    checkout = async (cart) => {
        try {
            let response = await this.postRequest('/product/checkout', {cart_detail: cart}, true, false)
            return response;
        } catch(err){
            throw err
        }
    }
};
export const product_svc = new ProductService();
export default ProductService;