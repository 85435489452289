import AdminBreadCrumb from "../../../components/admin/admin-bredcrumb.component"
import BrandForm from "./brand-form.component"
import BrandService from "../../../services/brand.service"
import { toast } from "react-toastify"
import { useNavigate } from "react-router-dom"

const BrandCreatePage = () => {
    let navigate = useNavigate();
    const submitForm = async (data) => {
        try{
            let brand_svc = new BrandService()
            let response = await brand_svc.create(data);
            toast.success(response.msg)
            navigate("/admin/brand")
        } catch(err){
            toast.error(err);
        }
    }
    return (<>
        <div className="container-fluid px-4">
            <AdminBreadCrumb title={"Brand Create"} lable="Brand" showList={true}/>
            <div className="card mb-4">
                <div className="card-body">
                    <BrandForm data={{title: null, status: null, image: null}} submitForm={submitForm}/>
                </div>
            </div>

            
        </div>
    </>)
}

export default BrandCreatePage