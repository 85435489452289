import { useFormik } from "formik";
import {Form, Col} from "react-bootstrap";
import { SubmitCancel } from "../../../components/form.component";
import * as Yup from "yup";
import { useCallback, useEffect, useState } from "react";
import BrandService from "../../../services/brand.service";

import Select from 'react-select'


const CategoryForm = ({data, submitForm}) => {
    const [brands, setBrands] = useState();
    const brand_svc = new BrandService()

    const cateogryValidate = Yup.object({
        name: Yup.string().nullable().required("Title is required").min(2, "Title should be alteast 3 character long"),
        status: Yup.string().nullable().required("Status is required"),
        image: Yup.object().nullable()
    })

    const formik = useFormik({
        initialValues: data,
        validationSchema: cateogryValidate,
        onSubmit: (values) => {
            values.brands = values.brands.map((item) => item.value);
            // console.log(values)
            submitForm(values)
        }
    })

    const listAllBrands = useCallback(async() => {
        try{
            let response = await brand_svc.listAllBrands();
            if(response) {
                let options = response.result.map((item) => {
                    return {
                        value: item._id,
                        label: item.title
                    };
                })
                setBrands(options);
            }
        } catch(except) {
            // handle 
            console.error(except);
        }
    })
    useEffect(() => {
        if(data) {
            let sel_brands = data.brands ? brands.filter((item)=> data?.brands.includes(item.value)) : null;
            formik.setValues({
                ...data,
                brands: sel_brands
            })
        }
        listAllBrands();

    },[data])
    return (<>

        <Form onSubmit={formik.handleSubmit}>
            <Form.Group className="row mb-3">
                <Col as="label" className="form-label" sm={3}>Title: </Col>
                <Col sm={9}>
                    <Form.Control
                        type="text"
                        value={formik.values?.name}
                        size="sm"
                        name="name"
                        onChange={formik.handleChange}
                        placeholder="Enter Category Name..."
                    />
                    <span className="text-danger">{formik?.errors?.name}</span>
                </Col>
            </Form.Group>


            <Form.Group className="row mb-3">
                <Col as="label" className="form-label" sm={3}>Brands: </Col>
                <Col sm={9}>
                    {
                        brands && <>
                        <Select options={brands} isMulti={true} value={formik.values?.brands} onChange={(e) => {
                            formik.setValues({
                                ...formik.values,
                                brands: e
                            })
                        }}/>
                        <span className="text-danger">{formik?.errors?.brands}</span>
                        
                        </>
                    }
                </Col>
            </Form.Group>


            <Form.Group className="row mb-3">
                <Col as="label" className="form-label" sm={3}>Status: </Col>
                <Col sm={9}>
                    <Form.Select size="sm" name="status" value={formik.values?.status} onChange={formik.handleChange} >
                        <option>--Select Any One--</option>
                        <option value={'active'}>Active</option>
                        <option value={"inactive"}>In-active</option>
                    </Form.Select>
                    <span className="text-danger">{formik?.errors?.status}</span>
                </Col>
            </Form.Group>

            <Form.Group className="row mb-3">
                <Col as="label" className="form-label" sm={3}>File: </Col>
                <Col sm={6}>
                    <Form.Control
                        type="file"
                        size="sm"
                        accept="image/*"
                        name="image"
                        onChange={(e) => {
                            // 
                            formik.setValues({
                                ...formik.values,
                                image: e.target.files[0]
                            })
                        }}
                    />
                    <span className="text-danger">{formik?.errors?.image}</span>
                </Col>
                <Col>
                        {
                            formik?.values?.image ? 
                            <img src={(typeof(formik.values.image) === 'object' ? URL.createObjectURL(formik.values.image) : process.env.REACT_APP_IMAGE_URL+formik.values.image)} className="img img-fluid" /> 
                            : <></>
                        }
                </Col>
            </Form.Group>


            <SubmitCancel />
        </Form>
    </>)
}

export default CategoryForm;