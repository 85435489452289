import AdminBreadCrumb from "../../../components/admin/admin-bredcrumb.component"
import ProductForm from "./product-form.component"
import ProductService from "../../../services/product.service"
import { toast } from "react-toastify"
import { useNavigate, useParams } from "react-router-dom"
import { useCallback, useEffect, useState } from "react"

const ProductEditPage = () => {
    let [product, setproduct] = useState();
    let params = useParams();
    let navigate = useNavigate();
    let product_svc = new ProductService()

    const getProductDetail = useCallback(async () => {
        try {

            let product_data = await product_svc.getProductById(params.product_id);
            if(product_data){
                
                setproduct(product_data.result)
            } else {
                toast.info("Product does not exists");
                navigate("/admin/product")
            }
        } catch(except) {
            console.log(except);
        }
    }, [])

    useEffect(() => {
        getProductDetail()
    }, [getProductDetail]);

    const submitForm = async (data) => {
        try{
            console.log("data", data);
            let updatedata = {
                name: data.name,
                description:  data.description,
                category_id:  data.category_id,
                price:  data.price,
                discount:  data.discount,
                is_featured:  data.is_featured ?? false,
                new_arrival: data.new_arrival ?? false,
                brand: data.brand ?? null,
                stock: data.stock,
                sku: data.sku,
                seller_id: data.seller_id ?? null,
                status: data.status,
                images: data.images,
            }

            let response = await product_svc.update(updatedata,params.product_id);
            toast.success(response.msg)
            navigate("/admin/product")
        } catch(err){
            toast.error(err);
        }
    }
    return (<>
        <div className="container-fluid px-4">
            <AdminBreadCrumb title={"Product Edit"} lable="Product" showList={true}/>
            <div className="card mb-4">
                <div className="card-body">
                    <ProductForm data={product} submitForm={submitForm}/>
                </div>
            </div>

            
        </div>
    </>)
}

export default ProductEditPage