import axios from "axios";
import { toast } from "react-toastify";

const axiosInstance = axios.create({
    baseURL: process.env.REACT_APP_API_URL,
    timeout: 30000,
    timeoutErrorMessage: "Server Timed out..",
    headers: {
        "accept": "application/json"
    }
});


axiosInstance.interceptors.response.use((response)=>{
    return response.data;
}, (error) => {
   if(error.response.status === 404) {
    // handle here
   } else if(error.response.status === 401) {
        // unauthorized 
        // TODO: Clear the token and redirect to login page
        window.location.href = "/login"
   } else if(error.response.status === 403) {
        //alert the user
        toast(error.response.data.msg) 
   } else {
        console.log("Intercept: ", error)
        throw error.response?.data?.msg;
   }
})

export default axiosInstance