import AdminBreadCrumb from "../../../components/admin/admin-bredcrumb.component"
import BannerForm from "./banner-form.component"
import BannerService from "../../../services/banner.service"
import { toast } from "react-toastify"
import { useNavigate, useParams } from "react-router-dom"
import { useCallback, useEffect, useState } from "react"

const BannerEditPage = () => {
    let [banner, setbanner] = useState();
    let params = useParams();
    let navigate = useNavigate();
    let banner_svc = new BannerService()

    const getBannerDetail = useCallback(async () => {
        try {

            let banner_data = await banner_svc.getBannerById(params.banner_id);
            if(banner_data){
                setbanner(banner_data.result)
            } else {
                toast.info("Banner does not exists");
                navigate("/admin/banner")
            }
        } catch(except) {
            console.log(except);
        }
    }, [])

    useEffect(() => {
        getBannerDetail()
    }, [getBannerDetail]);

    const submitForm = async (data) => {
        try{
            let updatedata = {
                title: data.title, 
                status: data.status, 
                image: data.image
            }

            let response = await banner_svc.update(updatedata,params.banner_id);
            toast.success(response.msg)
            navigate("/admin/banner")
        } catch(err){
            toast.error(err);
        }
    }
    return (<>
        <div className="container-fluid px-4">
            <AdminBreadCrumb title={"Banner Edit"} lable="Banner" showList={true}/>
            <div className="card mb-4">
                <div className="card-body">
                    <BannerForm data={banner} submitForm={submitForm}/>
                </div>
            </div>

            
        </div>
    </>)
}

export default BannerEditPage