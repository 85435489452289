import { useCallback, useEffect, useState } from "react";
import { Container, Row, Col, Form, Button } from "react-bootstrap";
import { useParams } from "react-router-dom"
import { product_svc } from "../../services/product.service";
import SliderComponent from "../../components/home/slider/slider.component";
import {useDispatch} from "react-redux";
import { setItemtoCart } from "../../reducers/cart.slicer";
import { toast } from "react-toastify";

const ProductDetailPage = () => {
    let params = useParams();
    let [detail, setDetail] = useState();
    let [images, setImages] = useState();

    let dispatch = useDispatch();

    let [qty, setQty] = useState(0);

    const productDetailBySlug = useCallback(async () => {
        try {
            let resposne = await product_svc.getProductBySlug(params.slug);
            if(resposne.status) {
                setDetail(resposne.result);
                if(resposne.result.images) {
                    let img_url = resposne.result.images.map((item) => process.env.REACT_APP_IMAGE_URL+item);
                    setImages(img_url);
                }
            }
        } catch(err) {
            // 
        }
    });
    useEffect(() => {
        productDetailBySlug();
    }, [])


    const addToCart = (e) => {
        e.preventDefault();
        try{
            let current_item = {
                product_id: detail._id ,
                qty: qty
            }
            dispatch(setItemtoCart(current_item))
            toast.success('Item added in the cart')
        } catch(err) {

        }
    }

    return (<>
    {
        detail ? <>
<Container>
        <Row className="mt-3">
            <Col sm={12} md={6}>
                <SliderComponent
                    images={images}
                    showThumb={true}
                    showArrows={false}
                    showDots={false}
                />
            </Col>
            <Col sm={12} md={6}>
                <h4>{detail?.name}</h4>
                <hr />

                <Row>
                    <Col>
                        <p>
                            <span className="me-3">Npr. {detail?.actual_price}</span>
                            {
                                detail?.discount ? <del className="text-danger">Npr. {detail.price}</del> : <></>
                            }
                        </p>
                        <p>
                            {detail.category_id.name}
                        </p>
                        <p>
                            {detail.brand.title}
                        </p>
                    </Col>
                </Row>

                    <Form onSubmit={addToCart}>
                        <Form.Group className="row">
                            <Col sm={6}>
                                <Form.Control type="number" name="qty" onChange={(e) => {
                                    setQty(e.target.value)
                                }} size="sm" defaultValue={0} min="0"/>
                            </Col>
                            <Col sm={6}>
                                <Button type="submit" variant="warning" size="sm">
                                    Add to Cart
                                </Button>
                            </Col>
                        </Form.Group>

                        
                    </Form>

            </Col>
        </Row>

        <Row>
            <Col sm={12}>
                <h4>Description</h4>
                <hr />
            </Col>
            <Col sm={12} dangerouslySetInnerHTML={{__html: detail.description}}></Col>
        </Row>
    </Container>
        </> :
        <></>
    }
    </>)
}
export default ProductDetailPage