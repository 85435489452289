import HttpService from "./http-request.service";

class BannerService extends HttpService{
    create = async (data) => {
        try{
            let formData = new FormData();
            
            if(data.image) {
                formData.append('image', data.image, data.image.name);
                delete data.image;
            }
            Object.keys(data).forEach((key) => {
                formData.append(key, data[key])
            })
            let response = await this.postRequest('/banner', formData,true, true);
            if(response.status) {
                return response
            } else {
                throw response;
            }
        } catch(except) {
            throw except;
        }
    }

    listAllBanners = async () => {
        try {
            let response = await this.getRequest("/banner", false);
            return response
        } catch(err) {
            throw err;
        }
    }

    deleteBannerById = async(id) => {
        try {
            let del = await this.deleteRequest("/banner/"+id, true);
            return  del;
        } catch(except) {
            throw except
        }
    }

    getBannerById = async (id) => {
        try {
            let banner_response = await this.getRequest("/banner/"+id);
            return  banner_response;
        } catch(except) {
            throw except
        }
    }

    update = async (data, id) => {
        try {
            let formData = new FormData();
            if(data.image && typeof(data.image) === 'object') {
                formData.append('image', data.image, data.image.name);
                delete data.image;
            }
            Object.keys(data).forEach((key) => {
                formData.append(key, data[key]);
            })
            let status = await this.updateRequest('/banner/'+id, formData, true, true);
            if(status) {
                return status;
            } else {
                throw status;
            }
        } catch(except) {
            throw except
        }
    }
};
export const banner_svc = new BannerService();

export default BannerService;